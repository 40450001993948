/* roboto-100normal - latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-display: swap;
  font-weight: 100;
  src:
    local('Roboto Thin '),
    local('Roboto-Thin'),
    url(/static/media/roboto-latin-100.987b8457.woff2) format('woff2'), 
    url(/static/media/roboto-latin-100.e9dbbe8a.woff) format('woff'); /* Modern Browsers */
}

/* roboto-100italic - latin */
@font-face {
  font-family: 'Roboto';
  font-style: italic;
  font-display: swap;
  font-weight: 100;
  src:
    local('Roboto Thin italic'),
    local('Roboto-Thinitalic'),
    url(/static/media/roboto-latin-100italic.6232f43d.woff2) format('woff2'), 
    url(/static/media/roboto-latin-100italic.d704bb3d.woff) format('woff'); /* Modern Browsers */
}

/* roboto-300normal - latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-display: swap;
  font-weight: 300;
  src:
    local('Roboto Light '),
    local('Roboto-Light'),
    url(/static/media/roboto-latin-300.55536c8e.woff2) format('woff2'), 
    url(/static/media/roboto-latin-300.a1471d1d.woff) format('woff'); /* Modern Browsers */
}

/* roboto-300italic - latin */
@font-face {
  font-family: 'Roboto';
  font-style: italic;
  font-display: swap;
  font-weight: 300;
  src:
    local('Roboto Light italic'),
    local('Roboto-Lightitalic'),
    url(/static/media/roboto-latin-300italic.d69924b9.woff2) format('woff2'), 
    url(/static/media/roboto-latin-300italic.210a7c78.woff) format('woff'); /* Modern Browsers */
}

/* roboto-400normal - latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src:
    local('Roboto Regular '),
    local('Roboto-Regular'),
    url(/static/media/roboto-latin-400.5d4aeb4e.woff2) format('woff2'), 
    url(/static/media/roboto-latin-400.bafb105b.woff) format('woff'); /* Modern Browsers */
}

/* roboto-400italic - latin */
@font-face {
  font-family: 'Roboto';
  font-style: italic;
  font-display: swap;
  font-weight: 400;
  src:
    local('Roboto Regular italic'),
    local('Roboto-Regularitalic'),
    url(/static/media/roboto-latin-400italic.d8bcbe72.woff2) format('woff2'), 
    url(/static/media/roboto-latin-400italic.9680d5a0.woff) format('woff'); /* Modern Browsers */
}

/* roboto-500normal - latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-display: swap;
  font-weight: 500;
  src:
    local('Roboto Medium '),
    local('Roboto-Medium'),
    url(/static/media/roboto-latin-500.28546717.woff2) format('woff2'), 
    url(/static/media/roboto-latin-500.de8b7431.woff) format('woff'); /* Modern Browsers */
}

/* roboto-500italic - latin */
@font-face {
  font-family: 'Roboto';
  font-style: italic;
  font-display: swap;
  font-weight: 500;
  src:
    local('Roboto Medium italic'),
    local('Roboto-Mediumitalic'),
    url(/static/media/roboto-latin-500italic.510dec37.woff2) format('woff2'), 
    url(/static/media/roboto-latin-500italic.ffcc050b.woff) format('woff'); /* Modern Browsers */
}

/* roboto-700normal - latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-display: swap;
  font-weight: 700;
  src:
    local('Roboto Bold '),
    local('Roboto-Bold'),
    url(/static/media/roboto-latin-700.037d8304.woff2) format('woff2'), 
    url(/static/media/roboto-latin-700.cf6613d1.woff) format('woff'); /* Modern Browsers */
}

/* roboto-700italic - latin */
@font-face {
  font-family: 'Roboto';
  font-style: italic;
  font-display: swap;
  font-weight: 700;
  src:
    local('Roboto Bold italic'),
    local('Roboto-Bolditalic'),
    url(/static/media/roboto-latin-700italic.010c1aee.woff2) format('woff2'), 
    url(/static/media/roboto-latin-700italic.846d1890.woff) format('woff'); /* Modern Browsers */
}

/* roboto-900normal - latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-display: swap;
  font-weight: 900;
  src:
    local('Roboto Black '),
    local('Roboto-Black'),
    url(/static/media/roboto-latin-900.19b7a0ad.woff2) format('woff2'), 
    url(/static/media/roboto-latin-900.8c2ade50.woff) format('woff'); /* Modern Browsers */
}

/* roboto-900italic - latin */
@font-face {
  font-family: 'Roboto';
  font-style: italic;
  font-display: swap;
  font-weight: 900;
  src:
    local('Roboto Black italic'),
    local('Roboto-Blackitalic'),
    url(/static/media/roboto-latin-900italic.7b770d6c.woff2) format('woff2'), 
    url(/static/media/roboto-latin-900italic.bc833e72.woff) format('woff'); /* Modern Browsers */
}


.flag-icon-background {
  background-size: contain;
  background-position: 50%;
  background-repeat: no-repeat;
}
.flag-icon {
  background-size: contain;
  background-position: 50%;
  background-repeat: no-repeat;
  position: relative;
  display: inline-block;
  width: 1.33333333em;
  line-height: 1em;
}
.flag-icon:before {
  content: "\A0";
}
.flag-icon.flag-icon-squared {
  width: 1em;
}
.flag-icon-ad {
  background-image: url(/static/media/ad.d4227482.svg);
}
.flag-icon-ad.flag-icon-squared {
  background-image: url(/static/media/ad.e15ddeab.svg);
}
.flag-icon-ae {
  background-image: url(/static/media/ae.9fd1fcbf.svg);
}
.flag-icon-ae.flag-icon-squared {
  background-image: url(/static/media/ae.7847726d.svg);
}
.flag-icon-af {
  background-image: url(/static/media/af.008dc322.svg);
}
.flag-icon-af.flag-icon-squared {
  background-image: url(/static/media/af.fa735e43.svg);
}
.flag-icon-ag {
  background-image: url(/static/media/ag.cce32c73.svg);
}
.flag-icon-ag.flag-icon-squared {
  background-image: url(/static/media/ag.53a60086.svg);
}
.flag-icon-ai {
  background-image: url(/static/media/ai.c2f29c4d.svg);
}
.flag-icon-ai.flag-icon-squared {
  background-image: url(/static/media/ai.9fe4d6b7.svg);
}
.flag-icon-al {
  background-image: url(/static/media/al.4eb491e7.svg);
}
.flag-icon-al.flag-icon-squared {
  background-image: url(/static/media/al.46612c27.svg);
}
.flag-icon-am {
  background-image: url(/static/media/am.06509258.svg);
}
.flag-icon-am.flag-icon-squared {
  background-image: url(/static/media/am.c86a9e16.svg);
}
.flag-icon-ao {
  background-image: url(/static/media/ao.8b6f2ec2.svg);
}
.flag-icon-ao.flag-icon-squared {
  background-image: url(/static/media/ao.ab8cc21b.svg);
}
.flag-icon-aq {
  background-image: url(/static/media/aq.65afe1f1.svg);
}
.flag-icon-aq.flag-icon-squared {
  background-image: url(/static/media/aq.65448909.svg);
}
.flag-icon-ar {
  background-image: url(/static/media/ar.d205ca13.svg);
}
.flag-icon-ar.flag-icon-squared {
  background-image: url(/static/media/ar.78827b0b.svg);
}
.flag-icon-as {
  background-image: url(/static/media/as.16f433a6.svg);
}
.flag-icon-as.flag-icon-squared {
  background-image: url(/static/media/as.27f3e372.svg);
}
.flag-icon-at {
  background-image: url(/static/media/at.e2634e96.svg);
}
.flag-icon-at.flag-icon-squared {
  background-image: url(/static/media/at.5ab33f74.svg);
}
.flag-icon-au {
  background-image: url(/static/media/au.503a3a98.svg);
}
.flag-icon-au.flag-icon-squared {
  background-image: url(/static/media/au.9b18ee04.svg);
}
.flag-icon-aw {
  background-image: url(/static/media/aw.47ea7038.svg);
}
.flag-icon-aw.flag-icon-squared {
  background-image: url(/static/media/aw.f159ec16.svg);
}
.flag-icon-ax {
  background-image: url(/static/media/ax.c26f8374.svg);
}
.flag-icon-ax.flag-icon-squared {
  background-image: url(/static/media/ax.fdd00c43.svg);
}
.flag-icon-az {
  background-image: url(/static/media/az.451284ce.svg);
}
.flag-icon-az.flag-icon-squared {
  background-image: url(/static/media/az.0b4258df.svg);
}
.flag-icon-ba {
  background-image: url(/static/media/ba.32231661.svg);
}
.flag-icon-ba.flag-icon-squared {
  background-image: url(/static/media/ba.a9dbadd7.svg);
}
.flag-icon-bb {
  background-image: url(/static/media/bb.9873885f.svg);
}
.flag-icon-bb.flag-icon-squared {
  background-image: url(/static/media/bb.45c62450.svg);
}
.flag-icon-bd {
  background-image: url(/static/media/bd.5102bab0.svg);
}
.flag-icon-bd.flag-icon-squared {
  background-image: url(/static/media/bd.c4a1485f.svg);
}
.flag-icon-be {
  background-image: url(/static/media/be.27d8ca49.svg);
}
.flag-icon-be.flag-icon-squared {
  background-image: url(/static/media/be.f1e78c8b.svg);
}
.flag-icon-bf {
  background-image: url(/static/media/bf.9a958401.svg);
}
.flag-icon-bf.flag-icon-squared {
  background-image: url(/static/media/bf.48eb94de.svg);
}
.flag-icon-bg {
  background-image: url(/static/media/bg.3d762564.svg);
}
.flag-icon-bg.flag-icon-squared {
  background-image: url(/static/media/bg.7163fe76.svg);
}
.flag-icon-bh {
  background-image: url(/static/media/bh.ef135f3c.svg);
}
.flag-icon-bh.flag-icon-squared {
  background-image: url(/static/media/bh.90ad3cbd.svg);
}
.flag-icon-bi {
  background-image: url(/static/media/bi.06f36479.svg);
}
.flag-icon-bi.flag-icon-squared {
  background-image: url(/static/media/bi.75d5af3d.svg);
}
.flag-icon-bj {
  background-image: url(/static/media/bj.c81e8915.svg);
}
.flag-icon-bj.flag-icon-squared {
  background-image: url(/static/media/bj.b6387659.svg);
}
.flag-icon-bl {
  background-image: url(/static/media/bl.38e27b68.svg);
}
.flag-icon-bl.flag-icon-squared {
  background-image: url(/static/media/bl.4d724b8e.svg);
}
.flag-icon-bm {
  background-image: url(/static/media/bm.0fdefae8.svg);
}
.flag-icon-bm.flag-icon-squared {
  background-image: url(/static/media/bm.09839e2c.svg);
}
.flag-icon-bn {
  background-image: url(/static/media/bn.1d4e6091.svg);
}
.flag-icon-bn.flag-icon-squared {
  background-image: url(/static/media/bn.0adbb664.svg);
}
.flag-icon-bo {
  background-image: url(/static/media/bo.4128202a.svg);
}
.flag-icon-bo.flag-icon-squared {
  background-image: url(/static/media/bo.eab17936.svg);
}
.flag-icon-bq {
  background-image: url(/static/media/bq.b551016f.svg);
}
.flag-icon-bq.flag-icon-squared {
  background-image: url(/static/media/bq.d6da2e84.svg);
}
.flag-icon-br {
  background-image: url(/static/media/br.87032851.svg);
}
.flag-icon-br.flag-icon-squared {
  background-image: url(/static/media/br.ef701aba.svg);
}
.flag-icon-bs {
  background-image: url(/static/media/bs.9f8a4eae.svg);
}
.flag-icon-bs.flag-icon-squared {
  background-image: url(/static/media/bs.6fe877e1.svg);
}
.flag-icon-bt {
  background-image: url(/static/media/bt.65b20c56.svg);
}
.flag-icon-bt.flag-icon-squared {
  background-image: url(/static/media/bt.dbb1623f.svg);
}
.flag-icon-bv {
  background-image: url(/static/media/bv.78bef910.svg);
}
.flag-icon-bv.flag-icon-squared {
  background-image: url(/static/media/bv.b70ab2f2.svg);
}
.flag-icon-bw {
  background-image: url(/static/media/bw.d1585fdf.svg);
}
.flag-icon-bw.flag-icon-squared {
  background-image: url(/static/media/bw.d9e5e45f.svg);
}
.flag-icon-by {
  background-image: url(/static/media/by.f4cbd761.svg);
}
.flag-icon-by.flag-icon-squared {
  background-image: url(/static/media/by.80b2d2dd.svg);
}
.flag-icon-bz {
  background-image: url(/static/media/bz.64d617ea.svg);
}
.flag-icon-bz.flag-icon-squared {
  background-image: url(/static/media/bz.e6b5e204.svg);
}
.flag-icon-ca {
  background-image: url(/static/media/ca.c976442e.svg);
}
.flag-icon-ca.flag-icon-squared {
  background-image: url(/static/media/ca.8678fc67.svg);
}
.flag-icon-cc {
  background-image: url(/static/media/cc.2da4bb97.svg);
}
.flag-icon-cc.flag-icon-squared {
  background-image: url(/static/media/cc.12b2a484.svg);
}
.flag-icon-cd {
  background-image: url(/static/media/cd.cd346cdc.svg);
}
.flag-icon-cd.flag-icon-squared {
  background-image: url(/static/media/cd.b43f872e.svg);
}
.flag-icon-cf {
  background-image: url(/static/media/cf.2171101e.svg);
}
.flag-icon-cf.flag-icon-squared {
  background-image: url(/static/media/cf.1bc217dc.svg);
}
.flag-icon-cg {
  background-image: url(/static/media/cg.4396b867.svg);
}
.flag-icon-cg.flag-icon-squared {
  background-image: url(/static/media/cg.8373836c.svg);
}
.flag-icon-ch {
  background-image: url(/static/media/ch.9c26f60a.svg);
}
.flag-icon-ch.flag-icon-squared {
  background-image: url(/static/media/ch.252c409b.svg);
}
.flag-icon-ci {
  background-image: url(/static/media/ci.d939dcac.svg);
}
.flag-icon-ci.flag-icon-squared {
  background-image: url(/static/media/ci.26a62321.svg);
}
.flag-icon-ck {
  background-image: url(/static/media/ck.960a7b5a.svg);
}
.flag-icon-ck.flag-icon-squared {
  background-image: url(/static/media/ck.22bf8119.svg);
}
.flag-icon-cl {
  background-image: url(/static/media/cl.8949f9e6.svg);
}
.flag-icon-cl.flag-icon-squared {
  background-image: url(/static/media/cl.6d63ff70.svg);
}
.flag-icon-cm {
  background-image: url(/static/media/cm.5799ad4c.svg);
}
.flag-icon-cm.flag-icon-squared {
  background-image: url(/static/media/cm.c972441e.svg);
}
.flag-icon-cn {
  background-image: url(/static/media/cn.02c229de.svg);
}
.flag-icon-cn.flag-icon-squared {
  background-image: url(/static/media/cn.a94c9394.svg);
}
.flag-icon-co {
  background-image: url(/static/media/co.3b252a1a.svg);
}
.flag-icon-co.flag-icon-squared {
  background-image: url(/static/media/co.41244c20.svg);
}
.flag-icon-cr {
  background-image: url(/static/media/cr.7b4ebd50.svg);
}
.flag-icon-cr.flag-icon-squared {
  background-image: url(/static/media/cr.657d7dbc.svg);
}
.flag-icon-cu {
  background-image: url(/static/media/cu.750c91b2.svg);
}
.flag-icon-cu.flag-icon-squared {
  background-image: url(/static/media/cu.0b42edab.svg);
}
.flag-icon-cv {
  background-image: url(/static/media/cv.f9922e01.svg);
}
.flag-icon-cv.flag-icon-squared {
  background-image: url(/static/media/cv.20a8cfff.svg);
}
.flag-icon-cw {
  background-image: url(/static/media/cw.f1b3043c.svg);
}
.flag-icon-cw.flag-icon-squared {
  background-image: url(/static/media/cw.69f19c22.svg);
}
.flag-icon-cx {
  background-image: url(/static/media/cx.aa81bb9e.svg);
}
.flag-icon-cx.flag-icon-squared {
  background-image: url(/static/media/cx.172a41ec.svg);
}
.flag-icon-cy {
  background-image: url(/static/media/cy.d069616c.svg);
}
.flag-icon-cy.flag-icon-squared {
  background-image: url(/static/media/cy.9f04989a.svg);
}
.flag-icon-cz {
  background-image: url(/static/media/cz.2339f3df.svg);
}
.flag-icon-cz.flag-icon-squared {
  background-image: url(/static/media/cz.80879b0e.svg);
}
.flag-icon-de {
  background-image: url(/static/media/de.3e726c2b.svg);
}
.flag-icon-de.flag-icon-squared {
  background-image: url(/static/media/de.4d7bac3b.svg);
}
.flag-icon-dj {
  background-image: url(/static/media/dj.423c4156.svg);
}
.flag-icon-dj.flag-icon-squared {
  background-image: url(/static/media/dj.0c386d22.svg);
}
.flag-icon-dk {
  background-image: url(/static/media/dk.eb1416e0.svg);
}
.flag-icon-dk.flag-icon-squared {
  background-image: url(/static/media/dk.d046fb5b.svg);
}
.flag-icon-dm {
  background-image: url(/static/media/dm.46f58d40.svg);
}
.flag-icon-dm.flag-icon-squared {
  background-image: url(/static/media/dm.664bf042.svg);
}
.flag-icon-do {
  background-image: url(/static/media/do.79f8bf8c.svg);
}
.flag-icon-do.flag-icon-squared {
  background-image: url(/static/media/do.07d2b1ed.svg);
}
.flag-icon-dz {
  background-image: url(/static/media/dz.4be984a3.svg);
}
.flag-icon-dz.flag-icon-squared {
  background-image: url(/static/media/dz.b03e5aec.svg);
}
.flag-icon-ec {
  background-image: url(/static/media/ec.5d6fdbf8.svg);
}
.flag-icon-ec.flag-icon-squared {
  background-image: url(/static/media/ec.5e9624df.svg);
}
.flag-icon-ee {
  background-image: url(/static/media/ee.6088c9ce.svg);
}
.flag-icon-ee.flag-icon-squared {
  background-image: url(/static/media/ee.9e932a62.svg);
}
.flag-icon-eg {
  background-image: url(/static/media/eg.6b83ab95.svg);
}
.flag-icon-eg.flag-icon-squared {
  background-image: url(/static/media/eg.2ea321dd.svg);
}
.flag-icon-eh {
  background-image: url(/static/media/eh.3b662831.svg);
}
.flag-icon-eh.flag-icon-squared {
  background-image: url(/static/media/eh.2a0e164e.svg);
}
.flag-icon-er {
  background-image: url(/static/media/er.e5e5e397.svg);
}
.flag-icon-er.flag-icon-squared {
  background-image: url(/static/media/er.bdfbf04c.svg);
}
.flag-icon-es {
  background-image: url(/static/media/es.50623e6a.svg);
}
.flag-icon-es.flag-icon-squared {
  background-image: url(/static/media/es.afff2473.svg);
}
.flag-icon-et {
  background-image: url(/static/media/et.2ebb0d3d.svg);
}
.flag-icon-et.flag-icon-squared {
  background-image: url(/static/media/et.1d986679.svg);
}
.flag-icon-fi {
  background-image: url(/static/media/fi.2649533e.svg);
}
.flag-icon-fi.flag-icon-squared {
  background-image: url(/static/media/fi.b48413be.svg);
}
.flag-icon-fj {
  background-image: url(/static/media/fj.60620e85.svg);
}
.flag-icon-fj.flag-icon-squared {
  background-image: url(/static/media/fj.76a7a39e.svg);
}
.flag-icon-fk {
  background-image: url(/static/media/fk.519e3de5.svg);
}
.flag-icon-fk.flag-icon-squared {
  background-image: url(/static/media/fk.aeb2d588.svg);
}
.flag-icon-fm {
  background-image: url(/static/media/fm.59c5190c.svg);
}
.flag-icon-fm.flag-icon-squared {
  background-image: url(/static/media/fm.3f19d612.svg);
}
.flag-icon-fo {
  background-image: url(/static/media/fo.037e466d.svg);
}
.flag-icon-fo.flag-icon-squared {
  background-image: url(/static/media/fo.329cbed5.svg);
}
.flag-icon-fr {
  background-image: url(/static/media/fr.b1156355.svg);
}
.flag-icon-fr.flag-icon-squared {
  background-image: url(/static/media/fr.f8952213.svg);
}
.flag-icon-ga {
  background-image: url(/static/media/ga.29f203bb.svg);
}
.flag-icon-ga.flag-icon-squared {
  background-image: url(/static/media/ga.33d27fe1.svg);
}
.flag-icon-gb {
  background-image: url(/static/media/gb.d3ddd602.svg);
}
.flag-icon-gb.flag-icon-squared {
  background-image: url(/static/media/gb.5db9fea0.svg);
}
.flag-icon-gd {
  background-image: url(/static/media/gd.8e690a5a.svg);
}
.flag-icon-gd.flag-icon-squared {
  background-image: url(/static/media/gd.56fdbab2.svg);
}
.flag-icon-ge {
  background-image: url(/static/media/ge.16f859b5.svg);
}
.flag-icon-ge.flag-icon-squared {
  background-image: url(/static/media/ge.d3665bf1.svg);
}
.flag-icon-gf {
  background-image: url(/static/media/gf.38dfa23a.svg);
}
.flag-icon-gf.flag-icon-squared {
  background-image: url(/static/media/gf.cabf9781.svg);
}
.flag-icon-gg {
  background-image: url(/static/media/gg.98f67a6f.svg);
}
.flag-icon-gg.flag-icon-squared {
  background-image: url(/static/media/gg.357e1e33.svg);
}
.flag-icon-gh {
  background-image: url(/static/media/gh.caedb912.svg);
}
.flag-icon-gh.flag-icon-squared {
  background-image: url(/static/media/gh.77872d15.svg);
}
.flag-icon-gi {
  background-image: url(/static/media/gi.dce455a7.svg);
}
.flag-icon-gi.flag-icon-squared {
  background-image: url(/static/media/gi.b0015a50.svg);
}
.flag-icon-gl {
  background-image: url(/static/media/gl.2490aa08.svg);
}
.flag-icon-gl.flag-icon-squared {
  background-image: url(/static/media/gl.48bf3e4e.svg);
}
.flag-icon-gm {
  background-image: url(/static/media/gm.50fe2799.svg);
}
.flag-icon-gm.flag-icon-squared {
  background-image: url(/static/media/gm.414139d5.svg);
}
.flag-icon-gn {
  background-image: url(/static/media/gn.36a3e9a3.svg);
}
.flag-icon-gn.flag-icon-squared {
  background-image: url(/static/media/gn.1ce64523.svg);
}
.flag-icon-gp {
  background-image: url(/static/media/gp.fa4cab3e.svg);
}
.flag-icon-gp.flag-icon-squared {
  background-image: url(/static/media/gp.c2c4da0e.svg);
}
.flag-icon-gq {
  background-image: url(/static/media/gq.30ed019c.svg);
}
.flag-icon-gq.flag-icon-squared {
  background-image: url(/static/media/gq.80b56bda.svg);
}
.flag-icon-gr {
  background-image: url(/static/media/gr.471d733a.svg);
}
.flag-icon-gr.flag-icon-squared {
  background-image: url(/static/media/gr.0bed56a8.svg);
}
.flag-icon-gs {
  background-image: url(/static/media/gs.0ee2d8c9.svg);
}
.flag-icon-gs.flag-icon-squared {
  background-image: url(/static/media/gs.6adf96a8.svg);
}
.flag-icon-gt {
  background-image: url(/static/media/gt.656c9899.svg);
}
.flag-icon-gt.flag-icon-squared {
  background-image: url(/static/media/gt.d6b5b664.svg);
}
.flag-icon-gu {
  background-image: url(/static/media/gu.2284e60e.svg);
}
.flag-icon-gu.flag-icon-squared {
  background-image: url(/static/media/gu.64936a10.svg);
}
.flag-icon-gw {
  background-image: url(/static/media/gw.c1e88a91.svg);
}
.flag-icon-gw.flag-icon-squared {
  background-image: url(/static/media/gw.5ecbd93c.svg);
}
.flag-icon-gy {
  background-image: url(/static/media/gy.79fcf270.svg);
}
.flag-icon-gy.flag-icon-squared {
  background-image: url(/static/media/gy.0653b318.svg);
}
.flag-icon-hk {
  background-image: url(/static/media/hk.7428ec1c.svg);
}
.flag-icon-hk.flag-icon-squared {
  background-image: url(/static/media/hk.4a0f09ba.svg);
}
.flag-icon-hm {
  background-image: url(/static/media/hm.fe514431.svg);
}
.flag-icon-hm.flag-icon-squared {
  background-image: url(/static/media/hm.fc838ac0.svg);
}
.flag-icon-hn {
  background-image: url(/static/media/hn.9b9bee13.svg);
}
.flag-icon-hn.flag-icon-squared {
  background-image: url(/static/media/hn.c94622ad.svg);
}
.flag-icon-hr {
  background-image: url(/static/media/hr.4680d632.svg);
}
.flag-icon-hr.flag-icon-squared {
  background-image: url(/static/media/hr.88f38f33.svg);
}
.flag-icon-ht {
  background-image: url(/static/media/ht.fb289ca0.svg);
}
.flag-icon-ht.flag-icon-squared {
  background-image: url(/static/media/ht.34eb5f59.svg);
}
.flag-icon-hu {
  background-image: url(/static/media/hu.0d7409f8.svg);
}
.flag-icon-hu.flag-icon-squared {
  background-image: url(/static/media/hu.e5e334fd.svg);
}
.flag-icon-id {
  background-image: url(/static/media/id.17b99676.svg);
}
.flag-icon-id.flag-icon-squared {
  background-image: url(/static/media/id.9f708fe5.svg);
}
.flag-icon-ie {
  background-image: url(/static/media/ie.c68ff961.svg);
}
.flag-icon-ie.flag-icon-squared {
  background-image: url(/static/media/ie.798a56e0.svg);
}
.flag-icon-il {
  background-image: url(/static/media/il.874270d6.svg);
}
.flag-icon-il.flag-icon-squared {
  background-image: url(/static/media/il.c36a011d.svg);
}
.flag-icon-im {
  background-image: url(/static/media/im.8c10222d.svg);
}
.flag-icon-im.flag-icon-squared {
  background-image: url(/static/media/im.ac0c825e.svg);
}
.flag-icon-in {
  background-image: url(/static/media/in.e4ab7bd0.svg);
}
.flag-icon-in.flag-icon-squared {
  background-image: url(/static/media/in.209ae8e9.svg);
}
.flag-icon-io {
  background-image: url(/static/media/io.3ddd1280.svg);
}
.flag-icon-io.flag-icon-squared {
  background-image: url(/static/media/io.a45231d4.svg);
}
.flag-icon-iq {
  background-image: url(/static/media/iq.be991997.svg);
}
.flag-icon-iq.flag-icon-squared {
  background-image: url(/static/media/iq.8d936728.svg);
}
.flag-icon-ir {
  background-image: url(/static/media/ir.23e0f96c.svg);
}
.flag-icon-ir.flag-icon-squared {
  background-image: url(/static/media/ir.7bf140ab.svg);
}
.flag-icon-is {
  background-image: url(/static/media/is.2ce20c50.svg);
}
.flag-icon-is.flag-icon-squared {
  background-image: url(/static/media/is.ae44c07e.svg);
}
.flag-icon-it {
  background-image: url(/static/media/it.8d15de04.svg);
}
.flag-icon-it.flag-icon-squared {
  background-image: url(/static/media/it.22b99ae7.svg);
}
.flag-icon-je {
  background-image: url(/static/media/je.e0932aed.svg);
}
.flag-icon-je.flag-icon-squared {
  background-image: url(/static/media/je.ab89781e.svg);
}
.flag-icon-jm {
  background-image: url(/static/media/jm.67f96b2f.svg);
}
.flag-icon-jm.flag-icon-squared {
  background-image: url(/static/media/jm.b7b13124.svg);
}
.flag-icon-jo {
  background-image: url(/static/media/jo.9e2f2b3a.svg);
}
.flag-icon-jo.flag-icon-squared {
  background-image: url(/static/media/jo.51302798.svg);
}
.flag-icon-jp {
  background-image: url(/static/media/jp.3e72015c.svg);
}
.flag-icon-jp.flag-icon-squared {
  background-image: url(/static/media/jp.16a568ca.svg);
}
.flag-icon-ke {
  background-image: url(/static/media/ke.dd8a91b8.svg);
}
.flag-icon-ke.flag-icon-squared {
  background-image: url(/static/media/ke.87900162.svg);
}
.flag-icon-kg {
  background-image: url(/static/media/kg.1cfa1c79.svg);
}
.flag-icon-kg.flag-icon-squared {
  background-image: url(/static/media/kg.5908392a.svg);
}
.flag-icon-kh {
  background-image: url(/static/media/kh.5a13865d.svg);
}
.flag-icon-kh.flag-icon-squared {
  background-image: url(/static/media/kh.61a4b374.svg);
}
.flag-icon-ki {
  background-image: url(/static/media/ki.db7e40f6.svg);
}
.flag-icon-ki.flag-icon-squared {
  background-image: url(/static/media/ki.cdeef8df.svg);
}
.flag-icon-km {
  background-image: url(/static/media/km.9b06043d.svg);
}
.flag-icon-km.flag-icon-squared {
  background-image: url(/static/media/km.eb69abb6.svg);
}
.flag-icon-kn {
  background-image: url(/static/media/kn.bde74c6d.svg);
}
.flag-icon-kn.flag-icon-squared {
  background-image: url(/static/media/kn.4ad12564.svg);
}
.flag-icon-kp {
  background-image: url(/static/media/kp.9c534291.svg);
}
.flag-icon-kp.flag-icon-squared {
  background-image: url(/static/media/kp.f08daf33.svg);
}
.flag-icon-kr {
  background-image: url(/static/media/kr.60fde7fc.svg);
}
.flag-icon-kr.flag-icon-squared {
  background-image: url(/static/media/kr.7fb0181b.svg);
}
.flag-icon-kw {
  background-image: url(/static/media/kw.496fa466.svg);
}
.flag-icon-kw.flag-icon-squared {
  background-image: url(/static/media/kw.33b3292e.svg);
}
.flag-icon-ky {
  background-image: url(/static/media/ky.5814c5a9.svg);
}
.flag-icon-ky.flag-icon-squared {
  background-image: url(/static/media/ky.ef1f6537.svg);
}
.flag-icon-kz {
  background-image: url(/static/media/kz.740ef4bf.svg);
}
.flag-icon-kz.flag-icon-squared {
  background-image: url(/static/media/kz.a19240f6.svg);
}
.flag-icon-la {
  background-image: url(/static/media/la.0f124ae3.svg);
}
.flag-icon-la.flag-icon-squared {
  background-image: url(/static/media/la.6b86f25a.svg);
}
.flag-icon-lb {
  background-image: url(/static/media/lb.e33a49a9.svg);
}
.flag-icon-lb.flag-icon-squared {
  background-image: url(/static/media/lb.56f32195.svg);
}
.flag-icon-lc {
  background-image: url(/static/media/lc.1c3a5554.svg);
}
.flag-icon-lc.flag-icon-squared {
  background-image: url(/static/media/lc.c056c2a7.svg);
}
.flag-icon-li {
  background-image: url(/static/media/li.fb5437d3.svg);
}
.flag-icon-li.flag-icon-squared {
  background-image: url(/static/media/li.748d1f99.svg);
}
.flag-icon-lk {
  background-image: url(/static/media/lk.497ee5b9.svg);
}
.flag-icon-lk.flag-icon-squared {
  background-image: url(/static/media/lk.f54e1ef9.svg);
}
.flag-icon-lr {
  background-image: url(/static/media/lr.309ccbd8.svg);
}
.flag-icon-lr.flag-icon-squared {
  background-image: url(/static/media/lr.6656f943.svg);
}
.flag-icon-ls {
  background-image: url(/static/media/ls.c0799ebf.svg);
}
.flag-icon-ls.flag-icon-squared {
  background-image: url(/static/media/ls.533cb320.svg);
}
.flag-icon-lt {
  background-image: url(/static/media/lt.c3aeac0d.svg);
}
.flag-icon-lt.flag-icon-squared {
  background-image: url(/static/media/lt.70975be0.svg);
}
.flag-icon-lu {
  background-image: url(/static/media/lu.c858787c.svg);
}
.flag-icon-lu.flag-icon-squared {
  background-image: url(/static/media/lu.2585715a.svg);
}
.flag-icon-lv {
  background-image: url(/static/media/lv.8b293d98.svg);
}
.flag-icon-lv.flag-icon-squared {
  background-image: url(/static/media/lv.f3c1274d.svg);
}
.flag-icon-ly {
  background-image: url(/static/media/ly.d089645e.svg);
}
.flag-icon-ly.flag-icon-squared {
  background-image: url(/static/media/ly.050ff9b0.svg);
}
.flag-icon-ma {
  background-image: url(/static/media/ma.60fbc221.svg);
}
.flag-icon-ma.flag-icon-squared {
  background-image: url(/static/media/ma.bee9c054.svg);
}
.flag-icon-mc {
  background-image: url(/static/media/mc.b4f4b90d.svg);
}
.flag-icon-mc.flag-icon-squared {
  background-image: url(/static/media/mc.78528abe.svg);
}
.flag-icon-md {
  background-image: url(/static/media/md.5f734d92.svg);
}
.flag-icon-md.flag-icon-squared {
  background-image: url(/static/media/md.75ec533a.svg);
}
.flag-icon-me {
  background-image: url(/static/media/me.76c434a6.svg);
}
.flag-icon-me.flag-icon-squared {
  background-image: url(/static/media/me.2d0c8f78.svg);
}
.flag-icon-mf {
  background-image: url(/static/media/mf.5b9ff36c.svg);
}
.flag-icon-mf.flag-icon-squared {
  background-image: url(/static/media/mf.487f7bd7.svg);
}
.flag-icon-mg {
  background-image: url(/static/media/mg.67f5922d.svg);
}
.flag-icon-mg.flag-icon-squared {
  background-image: url(/static/media/mg.91e10ba0.svg);
}
.flag-icon-mh {
  background-image: url(/static/media/mh.6d60cee3.svg);
}
.flag-icon-mh.flag-icon-squared {
  background-image: url(/static/media/mh.8f1f9134.svg);
}
.flag-icon-mk {
  background-image: url(/static/media/mk.ed091b88.svg);
}
.flag-icon-mk.flag-icon-squared {
  background-image: url(/static/media/mk.2413b107.svg);
}
.flag-icon-ml {
  background-image: url(/static/media/ml.e6f097f9.svg);
}
.flag-icon-ml.flag-icon-squared {
  background-image: url(/static/media/ml.204b0da4.svg);
}
.flag-icon-mm {
  background-image: url(/static/media/mm.92e9f832.svg);
}
.flag-icon-mm.flag-icon-squared {
  background-image: url(/static/media/mm.8d6d26bc.svg);
}
.flag-icon-mn {
  background-image: url(/static/media/mn.9ebe47eb.svg);
}
.flag-icon-mn.flag-icon-squared {
  background-image: url(/static/media/mn.933606d5.svg);
}
.flag-icon-mo {
  background-image: url(/static/media/mo.b6d4d1f6.svg);
}
.flag-icon-mo.flag-icon-squared {
  background-image: url(/static/media/mo.67acac75.svg);
}
.flag-icon-mp {
  background-image: url(/static/media/mp.c5eb7f23.svg);
}
.flag-icon-mp.flag-icon-squared {
  background-image: url(/static/media/mp.8a731cbc.svg);
}
.flag-icon-mq {
  background-image: url(/static/media/mq.bfeadb02.svg);
}
.flag-icon-mq.flag-icon-squared {
  background-image: url(/static/media/mq.a09e4865.svg);
}
.flag-icon-mr {
  background-image: url(/static/media/mr.a46829f1.svg);
}
.flag-icon-mr.flag-icon-squared {
  background-image: url(/static/media/mr.bf379763.svg);
}
.flag-icon-ms {
  background-image: url(/static/media/ms.e147bd2b.svg);
}
.flag-icon-ms.flag-icon-squared {
  background-image: url(/static/media/ms.ad88044d.svg);
}
.flag-icon-mt {
  background-image: url(/static/media/mt.a816f3a2.svg);
}
.flag-icon-mt.flag-icon-squared {
  background-image: url(/static/media/mt.f6e3733c.svg);
}
.flag-icon-mu {
  background-image: url(/static/media/mu.896330b7.svg);
}
.flag-icon-mu.flag-icon-squared {
  background-image: url(/static/media/mu.67c8f362.svg);
}
.flag-icon-mv {
  background-image: url(/static/media/mv.3c896bfd.svg);
}
.flag-icon-mv.flag-icon-squared {
  background-image: url(/static/media/mv.0fdc08c6.svg);
}
.flag-icon-mw {
  background-image: url(/static/media/mw.6073ddcf.svg);
}
.flag-icon-mw.flag-icon-squared {
  background-image: url(/static/media/mw.baf490bf.svg);
}
.flag-icon-mx {
  background-image: url(/static/media/mx.8ee3aa6a.svg);
}
.flag-icon-mx.flag-icon-squared {
  background-image: url(/static/media/mx.3aa223c8.svg);
}
.flag-icon-my {
  background-image: url(/static/media/my.e6739f40.svg);
}
.flag-icon-my.flag-icon-squared {
  background-image: url(/static/media/my.263aea34.svg);
}
.flag-icon-mz {
  background-image: url(/static/media/mz.ef4657da.svg);
}
.flag-icon-mz.flag-icon-squared {
  background-image: url(/static/media/mz.e99caf39.svg);
}
.flag-icon-na {
  background-image: url(/static/media/na.bb49a403.svg);
}
.flag-icon-na.flag-icon-squared {
  background-image: url(/static/media/na.74257fb2.svg);
}
.flag-icon-nc {
  background-image: url(/static/media/nc.b8c9f5e4.svg);
}
.flag-icon-nc.flag-icon-squared {
  background-image: url(/static/media/nc.d393b8fa.svg);
}
.flag-icon-ne {
  background-image: url(/static/media/ne.b7369ec7.svg);
}
.flag-icon-ne.flag-icon-squared {
  background-image: url(/static/media/ne.e56edd30.svg);
}
.flag-icon-nf {
  background-image: url(/static/media/nf.99af5a94.svg);
}
.flag-icon-nf.flag-icon-squared {
  background-image: url(/static/media/nf.801ee09f.svg);
}
.flag-icon-ng {
  background-image: url(/static/media/ng.992459a3.svg);
}
.flag-icon-ng.flag-icon-squared {
  background-image: url(/static/media/ng.520463e1.svg);
}
.flag-icon-ni {
  background-image: url(/static/media/ni.7b131ab3.svg);
}
.flag-icon-ni.flag-icon-squared {
  background-image: url(/static/media/ni.baafd7d7.svg);
}
.flag-icon-nl {
  background-image: url(/static/media/nl.d4811c27.svg);
}
.flag-icon-nl.flag-icon-squared {
  background-image: url(/static/media/nl.390aa40f.svg);
}
.flag-icon-no {
  background-image: url(/static/media/no.0b41df77.svg);
}
.flag-icon-no.flag-icon-squared {
  background-image: url(/static/media/no.b7a21f54.svg);
}
.flag-icon-np {
  background-image: url(/static/media/np.b66578a5.svg);
}
.flag-icon-np.flag-icon-squared {
  background-image: url(/static/media/np.27f0f4e7.svg);
}
.flag-icon-nr {
  background-image: url(/static/media/nr.d16edc69.svg);
}
.flag-icon-nr.flag-icon-squared {
  background-image: url(/static/media/nr.2ef5b7c8.svg);
}
.flag-icon-nu {
  background-image: url(/static/media/nu.433deb3d.svg);
}
.flag-icon-nu.flag-icon-squared {
  background-image: url(/static/media/nu.1db5a99d.svg);
}
.flag-icon-nz {
  background-image: url(/static/media/nz.3241e927.svg);
}
.flag-icon-nz.flag-icon-squared {
  background-image: url(/static/media/nz.7dab6e5e.svg);
}
.flag-icon-om {
  background-image: url(/static/media/om.1798270b.svg);
}
.flag-icon-om.flag-icon-squared {
  background-image: url(/static/media/om.b9b7d0bc.svg);
}
.flag-icon-pa {
  background-image: url(/static/media/pa.d0787677.svg);
}
.flag-icon-pa.flag-icon-squared {
  background-image: url(/static/media/pa.beb40ab6.svg);
}
.flag-icon-pe {
  background-image: url(/static/media/pe.ea95116f.svg);
}
.flag-icon-pe.flag-icon-squared {
  background-image: url(/static/media/pe.23591f9d.svg);
}
.flag-icon-pf {
  background-image: url(/static/media/pf.bab3b7a5.svg);
}
.flag-icon-pf.flag-icon-squared {
  background-image: url(/static/media/pf.2a69c581.svg);
}
.flag-icon-pg {
  background-image: url(/static/media/pg.68e1ce33.svg);
}
.flag-icon-pg.flag-icon-squared {
  background-image: url(/static/media/pg.0b07d418.svg);
}
.flag-icon-ph {
  background-image: url(/static/media/ph.12f36eed.svg);
}
.flag-icon-ph.flag-icon-squared {
  background-image: url(/static/media/ph.6ae85442.svg);
}
.flag-icon-pk {
  background-image: url(/static/media/pk.c2e1a159.svg);
}
.flag-icon-pk.flag-icon-squared {
  background-image: url(/static/media/pk.b67f80e0.svg);
}
.flag-icon-pl {
  background-image: url(/static/media/pl.562edca5.svg);
}
.flag-icon-pl.flag-icon-squared {
  background-image: url(/static/media/pl.3fe3bd51.svg);
}
.flag-icon-pm {
  background-image: url(/static/media/pm.1e97e8d7.svg);
}
.flag-icon-pm.flag-icon-squared {
  background-image: url(/static/media/pm.89993b1f.svg);
}
.flag-icon-pn {
  background-image: url(/static/media/pn.c4a2e49f.svg);
}
.flag-icon-pn.flag-icon-squared {
  background-image: url(/static/media/pn.48bd62e4.svg);
}
.flag-icon-pr {
  background-image: url(/static/media/pr.0811a051.svg);
}
.flag-icon-pr.flag-icon-squared {
  background-image: url(/static/media/pr.1d278b02.svg);
}
.flag-icon-ps {
  background-image: url(/static/media/ps.42f2391e.svg);
}
.flag-icon-ps.flag-icon-squared {
  background-image: url(/static/media/ps.2992f9b9.svg);
}
.flag-icon-pt {
  background-image: url(/static/media/pt.b908edae.svg);
}
.flag-icon-pt.flag-icon-squared {
  background-image: url(/static/media/pt.04fa443d.svg);
}
.flag-icon-pw {
  background-image: url(/static/media/pw.20a1d020.svg);
}
.flag-icon-pw.flag-icon-squared {
  background-image: url(/static/media/pw.78aaead2.svg);
}
.flag-icon-py {
  background-image: url(/static/media/py.bbc22e41.svg);
}
.flag-icon-py.flag-icon-squared {
  background-image: url(/static/media/py.a70b32d0.svg);
}
.flag-icon-qa {
  background-image: url(/static/media/qa.b314986b.svg);
}
.flag-icon-qa.flag-icon-squared {
  background-image: url(/static/media/qa.78909a6f.svg);
}
.flag-icon-re {
  background-image: url(/static/media/re.17909e37.svg);
}
.flag-icon-re.flag-icon-squared {
  background-image: url(/static/media/re.01fea3b6.svg);
}
.flag-icon-ro {
  background-image: url(/static/media/ro.625aca9e.svg);
}
.flag-icon-ro.flag-icon-squared {
  background-image: url(/static/media/ro.22278e13.svg);
}
.flag-icon-rs {
  background-image: url(/static/media/rs.291d0fb6.svg);
}
.flag-icon-rs.flag-icon-squared {
  background-image: url(/static/media/rs.d00d37d2.svg);
}
.flag-icon-ru {
  background-image: url(/static/media/ru.0cacf46e.svg);
}
.flag-icon-ru.flag-icon-squared {
  background-image: url(/static/media/ru.e3ee3b09.svg);
}
.flag-icon-rw {
  background-image: url(/static/media/rw.7fe5146b.svg);
}
.flag-icon-rw.flag-icon-squared {
  background-image: url(/static/media/rw.997fe41b.svg);
}
.flag-icon-sa {
  background-image: url(/static/media/sa.c36d1991.svg);
}
.flag-icon-sa.flag-icon-squared {
  background-image: url(/static/media/sa.135d0c86.svg);
}
.flag-icon-sb {
  background-image: url(/static/media/sb.aa819297.svg);
}
.flag-icon-sb.flag-icon-squared {
  background-image: url(/static/media/sb.d64e9848.svg);
}
.flag-icon-sc {
  background-image: url(/static/media/sc.e6584421.svg);
}
.flag-icon-sc.flag-icon-squared {
  background-image: url(/static/media/sc.ad1bcb4c.svg);
}
.flag-icon-sd {
  background-image: url(/static/media/sd.c466d90e.svg);
}
.flag-icon-sd.flag-icon-squared {
  background-image: url(/static/media/sd.7ab061d8.svg);
}
.flag-icon-se {
  background-image: url(/static/media/se.fd663a70.svg);
}
.flag-icon-se.flag-icon-squared {
  background-image: url(/static/media/se.92c66d83.svg);
}
.flag-icon-sg {
  background-image: url(/static/media/sg.5e6ed3f1.svg);
}
.flag-icon-sg.flag-icon-squared {
  background-image: url(/static/media/sg.9eb47fe7.svg);
}
.flag-icon-sh {
  background-image: url(/static/media/sh.6560d76b.svg);
}
.flag-icon-sh.flag-icon-squared {
  background-image: url(/static/media/sh.487ef1c8.svg);
}
.flag-icon-si {
  background-image: url(/static/media/si.31fbdc5b.svg);
}
.flag-icon-si.flag-icon-squared {
  background-image: url(/static/media/si.63ba8c45.svg);
}
.flag-icon-sj {
  background-image: url(/static/media/sj.ae547dbe.svg);
}
.flag-icon-sj.flag-icon-squared {
  background-image: url(/static/media/sj.ecbc9e93.svg);
}
.flag-icon-sk {
  background-image: url(/static/media/sk.b84444bf.svg);
}
.flag-icon-sk.flag-icon-squared {
  background-image: url(/static/media/sk.a5af0a28.svg);
}
.flag-icon-sl {
  background-image: url(/static/media/sl.ddbd1d9b.svg);
}
.flag-icon-sl.flag-icon-squared {
  background-image: url(/static/media/sl.f6315f74.svg);
}
.flag-icon-sm {
  background-image: url(/static/media/sm.3b1c9fb5.svg);
}
.flag-icon-sm.flag-icon-squared {
  background-image: url(/static/media/sm.f5665000.svg);
}
.flag-icon-sn {
  background-image: url(/static/media/sn.5b654e1a.svg);
}
.flag-icon-sn.flag-icon-squared {
  background-image: url(/static/media/sn.d2bec7ef.svg);
}
.flag-icon-so {
  background-image: url(/static/media/so.f91fb92c.svg);
}
.flag-icon-so.flag-icon-squared {
  background-image: url(/static/media/so.c1561217.svg);
}
.flag-icon-sr {
  background-image: url(/static/media/sr.788f3e2a.svg);
}
.flag-icon-sr.flag-icon-squared {
  background-image: url(/static/media/sr.be27d1ae.svg);
}
.flag-icon-ss {
  background-image: url(/static/media/ss.67001d2a.svg);
}
.flag-icon-ss.flag-icon-squared {
  background-image: url(/static/media/ss.e3933b44.svg);
}
.flag-icon-st {
  background-image: url(/static/media/st.1f545eb9.svg);
}
.flag-icon-st.flag-icon-squared {
  background-image: url(/static/media/st.d0a56dbb.svg);
}
.flag-icon-sv {
  background-image: url(/static/media/sv.1176ea28.svg);
}
.flag-icon-sv.flag-icon-squared {
  background-image: url(/static/media/sv.26ee8872.svg);
}
.flag-icon-sx {
  background-image: url(/static/media/sx.522d898c.svg);
}
.flag-icon-sx.flag-icon-squared {
  background-image: url(/static/media/sx.a7248001.svg);
}
.flag-icon-sy {
  background-image: url(/static/media/sy.64f0d2d7.svg);
}
.flag-icon-sy.flag-icon-squared {
  background-image: url(/static/media/sy.73690f50.svg);
}
.flag-icon-sz {
  background-image: url(/static/media/sz.cfb8269f.svg);
}
.flag-icon-sz.flag-icon-squared {
  background-image: url(/static/media/sz.dc2faeb7.svg);
}
.flag-icon-tc {
  background-image: url(/static/media/tc.d40761f2.svg);
}
.flag-icon-tc.flag-icon-squared {
  background-image: url(/static/media/tc.47c82761.svg);
}
.flag-icon-td {
  background-image: url(/static/media/td.a0923ddc.svg);
}
.flag-icon-td.flag-icon-squared {
  background-image: url(/static/media/td.f37a395c.svg);
}
.flag-icon-tf {
  background-image: url(/static/media/tf.4ab43cc9.svg);
}
.flag-icon-tf.flag-icon-squared {
  background-image: url(/static/media/tf.2e7dc1af.svg);
}
.flag-icon-tg {
  background-image: url(/static/media/tg.025deae8.svg);
}
.flag-icon-tg.flag-icon-squared {
  background-image: url(/static/media/tg.29fa137c.svg);
}
.flag-icon-th {
  background-image: url(/static/media/th.76fca72f.svg);
}
.flag-icon-th.flag-icon-squared {
  background-image: url(/static/media/th.904dd785.svg);
}
.flag-icon-tj {
  background-image: url(/static/media/tj.a8ed5244.svg);
}
.flag-icon-tj.flag-icon-squared {
  background-image: url(/static/media/tj.980d12c9.svg);
}
.flag-icon-tk {
  background-image: url(/static/media/tk.1959d9de.svg);
}
.flag-icon-tk.flag-icon-squared {
  background-image: url(/static/media/tk.7aaccddb.svg);
}
.flag-icon-tl {
  background-image: url(/static/media/tl.3c1ccf11.svg);
}
.flag-icon-tl.flag-icon-squared {
  background-image: url(/static/media/tl.0616faaa.svg);
}
.flag-icon-tm {
  background-image: url(/static/media/tm.b13d1440.svg);
}
.flag-icon-tm.flag-icon-squared {
  background-image: url(/static/media/tm.ea365f33.svg);
}
.flag-icon-tn {
  background-image: url(/static/media/tn.50cd9101.svg);
}
.flag-icon-tn.flag-icon-squared {
  background-image: url(/static/media/tn.fea87146.svg);
}
.flag-icon-to {
  background-image: url(/static/media/to.79354e72.svg);
}
.flag-icon-to.flag-icon-squared {
  background-image: url(/static/media/to.238ef1cd.svg);
}
.flag-icon-tr {
  background-image: url(/static/media/tr.ed6d5f37.svg);
}
.flag-icon-tr.flag-icon-squared {
  background-image: url(/static/media/tr.ce2e2e8e.svg);
}
.flag-icon-tt {
  background-image: url(/static/media/tt.c3647d9b.svg);
}
.flag-icon-tt.flag-icon-squared {
  background-image: url(/static/media/tt.4705d420.svg);
}
.flag-icon-tv {
  background-image: url(/static/media/tv.829fb9d8.svg);
}
.flag-icon-tv.flag-icon-squared {
  background-image: url(/static/media/tv.a595f49d.svg);
}
.flag-icon-tw {
  background-image: url(/static/media/tw.8a194685.svg);
}
.flag-icon-tw.flag-icon-squared {
  background-image: url(/static/media/tw.26cc9d59.svg);
}
.flag-icon-tz {
  background-image: url(/static/media/tz.88c89454.svg);
}
.flag-icon-tz.flag-icon-squared {
  background-image: url(/static/media/tz.d02545a1.svg);
}
.flag-icon-ua {
  background-image: url(/static/media/ua.a8b13525.svg);
}
.flag-icon-ua.flag-icon-squared {
  background-image: url(/static/media/ua.841d259d.svg);
}
.flag-icon-ug {
  background-image: url(/static/media/ug.be11ef39.svg);
}
.flag-icon-ug.flag-icon-squared {
  background-image: url(/static/media/ug.6d6f8896.svg);
}
.flag-icon-um {
  background-image: url(/static/media/um.8754eddf.svg);
}
.flag-icon-um.flag-icon-squared {
  background-image: url(/static/media/um.3d347682.svg);
}
.flag-icon-us {
  background-image: url(/static/media/us.ae656592.svg);
}
.flag-icon-us.flag-icon-squared {
  background-image: url(/static/media/us.8ec58318.svg);
}
.flag-icon-uy {
  background-image: url(/static/media/uy.79b02850.svg);
}
.flag-icon-uy.flag-icon-squared {
  background-image: url(/static/media/uy.adbc4992.svg);
}
.flag-icon-uz {
  background-image: url(/static/media/uz.eb1e00b8.svg);
}
.flag-icon-uz.flag-icon-squared {
  background-image: url(/static/media/uz.ca892343.svg);
}
.flag-icon-va {
  background-image: url(/static/media/va.21913d78.svg);
}
.flag-icon-va.flag-icon-squared {
  background-image: url(/static/media/va.90e9f73a.svg);
}
.flag-icon-vc {
  background-image: url(/static/media/vc.4ac5124f.svg);
}
.flag-icon-vc.flag-icon-squared {
  background-image: url(/static/media/vc.bbb52fa0.svg);
}
.flag-icon-ve {
  background-image: url(/static/media/ve.b2cd5a9a.svg);
}
.flag-icon-ve.flag-icon-squared {
  background-image: url(/static/media/ve.9f23d962.svg);
}
.flag-icon-vg {
  background-image: url(/static/media/vg.b37358a1.svg);
}
.flag-icon-vg.flag-icon-squared {
  background-image: url(/static/media/vg.a796b16d.svg);
}
.flag-icon-vi {
  background-image: url(/static/media/vi.4952d5bf.svg);
}
.flag-icon-vi.flag-icon-squared {
  background-image: url(/static/media/vi.0aa78210.svg);
}
.flag-icon-vn {
  background-image: url(/static/media/vn.6b3aef51.svg);
}
.flag-icon-vn.flag-icon-squared {
  background-image: url(/static/media/vn.a0081482.svg);
}
.flag-icon-vu {
  background-image: url(/static/media/vu.859836e7.svg);
}
.flag-icon-vu.flag-icon-squared {
  background-image: url(/static/media/vu.730801ab.svg);
}
.flag-icon-wf {
  background-image: url(/static/media/wf.e3ac728c.svg);
}
.flag-icon-wf.flag-icon-squared {
  background-image: url(/static/media/wf.05522b9f.svg);
}
.flag-icon-ws {
  background-image: url(/static/media/ws.405a2c5f.svg);
}
.flag-icon-ws.flag-icon-squared {
  background-image: url(/static/media/ws.3ea6d44f.svg);
}
.flag-icon-ye {
  background-image: url(/static/media/ye.b5840a84.svg);
}
.flag-icon-ye.flag-icon-squared {
  background-image: url(/static/media/ye.d13e1629.svg);
}
.flag-icon-yt {
  background-image: url(/static/media/yt.f06d254d.svg);
}
.flag-icon-yt.flag-icon-squared {
  background-image: url(/static/media/yt.b6042b9c.svg);
}
.flag-icon-za {
  background-image: url(/static/media/za.14e70522.svg);
}
.flag-icon-za.flag-icon-squared {
  background-image: url(/static/media/za.67ff2e10.svg);
}
.flag-icon-zm {
  background-image: url(/static/media/zm.a9ff495d.svg);
}
.flag-icon-zm.flag-icon-squared {
  background-image: url(/static/media/zm.3eef5dc0.svg);
}
.flag-icon-zw {
  background-image: url(/static/media/zw.6ac3949a.svg);
}
.flag-icon-zw.flag-icon-squared {
  background-image: url(/static/media/zw.8b885465.svg);
}
.flag-icon-es-ca {
  background-image: url(/static/media/es-ca.e9062265.svg);
}
.flag-icon-es-ca.flag-icon-squared {
  background-image: url(/static/media/es-ca.a35e6a4a.svg);
}
.flag-icon-es-ga {
  background-image: url(/static/media/es-ga.2618e21f.svg);
}
.flag-icon-es-ga.flag-icon-squared {
  background-image: url(/static/media/es-ga.151714df.svg);
}
.flag-icon-eu {
  background-image: url(/static/media/eu.ee7f4712.svg);
}
.flag-icon-eu.flag-icon-squared {
  background-image: url(/static/media/eu.4c73f57c.svg);
}
.flag-icon-gb-eng {
  background-image: url(/static/media/gb-eng.14167f77.svg);
}
.flag-icon-gb-eng.flag-icon-squared {
  background-image: url(/static/media/gb-eng.eabfeadc.svg);
}
.flag-icon-gb-nir {
  background-image: url(/static/media/gb-nir.43b61fea.svg);
}
.flag-icon-gb-nir.flag-icon-squared {
  background-image: url(/static/media/gb-nir.9cad35c4.svg);
}
.flag-icon-gb-sct {
  background-image: url(/static/media/gb-sct.4c2c379f.svg);
}
.flag-icon-gb-sct.flag-icon-squared {
  background-image: url(/static/media/gb-sct.31ef8bcf.svg);
}
.flag-icon-gb-wls {
  background-image: url(/static/media/gb-wls.2d554424.svg);
}
.flag-icon-gb-wls.flag-icon-squared {
  background-image: url(/static/media/gb-wls.85f8b842.svg);
}
.flag-icon-un {
  background-image: url(/static/media/un.bdaf37f9.svg);
}
.flag-icon-un.flag-icon-squared {
  background-image: url(/static/media/un.e6aabbd5.svg);
}
.flag-icon-xk {
  background-image: url(/static/media/xk.62bc9bcf.svg);
}
.flag-icon-xk.flag-icon-squared {
  background-image: url(/static/media/xk.bd62029e.svg);
}

:root{
  --font-sizes:lg, 2x, 3x, 4x, 5x;
  --font-sizes-values:1.33333333em, 2em, 3em, 4em, 5em;
  --rotates:30, 60, 90, 180, 270;
}

.flag-icon-flip-horizontal{
  -webkit-transform:scale(-1, 1);
          transform:scale(-1, 1);
}

.flag-icon-flip-vertical{
  -webkit-transform:scale(1, -1);
          transform:scale(1, -1);
}

.flag-icon-lg{
    font-size:1.33333333em;
  }

.flag-icon-2x{
    font-size:2em;
  }

.flag-icon-3x{
    font-size:3em;
  }

.flag-icon-4x{
    font-size:4em;
  }

.flag-icon-5x{
    font-size:5em;
  }

.flag-icon-rotate-30{
    -webkit-transform:rotate(30deg);
            transform:rotate(30deg);
  }

.flag-icon-rotate-60{
    -webkit-transform:rotate(60deg);
            transform:rotate(60deg);
  }

.flag-icon-rotate-90{
    -webkit-transform:rotate(90deg);
            transform:rotate(90deg);
  }

.flag-icon-rotate-180{
    -webkit-transform:rotate(180deg);
            transform:rotate(180deg);
  }

.flag-icon-rotate-270{
    -webkit-transform:rotate(270deg);
            transform:rotate(270deg);
  }

._3Zbwc {
    background: black;
    flex: 1 1;
    margin: 0;
    overflow: hidden;
    min-height: 50px;
    padding: 0;
    width: 100%;
    height: 100%;
}

._1MBkJ {
    background: black;
    flex: 1 1;
    margin: 0;
    overflow: hidden;
    min-height: 30px;
    padding: 0;
    width: 100%;
    height: 30px;
}

._1yysw {
    background: black;
    display: block;
    height: 0px;
    margin: 0;
    padding: 0;
    width: 0px;
}

._26rYC {
    background: black;
    display: block;
    height: calc(100% - 30px);
    margin: 0;
    padding: 0;
    width: 100%;
}

._1-it4 {
    background: #2c333e;
    height: 30px;
    margin: 0;
    padding: 0;
    position: relative;
    width: 100%;
}

._1-it4 ._25rEj {
    border: 1px solid grey;
    border-bottom-width: 0px;
    border-top-width: 0px;
    color: #fff;
    cursor: pointer;
    display: flex;
    height: 30px;
    transition: background-color 0.5s ease;
    width: 30px;
}

._1-it4 ._25rEj:hover {
    background: #2e5046;
}

._1-it4 ._25rEj svg {
    display: block;
    height: 1em;
    margin: auto;
    text-align: center;
}
._3LXDP {
    display: flex;
    justify-content: space-between;
    width: 680px;
    height: 430px;
    position: absolute;
    background-color: rgba(0, 0, 0, 0.5);
    color: white;
    font-size: 15px;
    z-index: 999;
}
._3oX6Y {
    margin-left: 30px;
    font-family: Arial, Helvetica, sans-serif;
    margin-top: 20px;
}
._zBQdX {
    margin-bottom: 5px;
    margin-top: 5px;
    background-color: rgba(0, 0, 0, 0.3);
    border: black solid 1px;
    height: 35px;
    width: 150px;
    border-radius: 7px;
    color: white;
}
._zBQdX:hover {
    background-color: rgba(191, 191, 191, 0.5);
}
._2VNf4 {
    width: 40px;
    height: 40px;
    margin-left: 300px;
    background-color: rgba(0, 0, 0, 0);
    color: grey;
    border: 0px;
    font-size: 30px;
}
._2VNf4:hover {
    color: white;
}

._1aqAY {
    display: flex;
    flex-direction: column;
    margin-right: 100px;
}
._1-it4 ._25rEj span {
    margin: auto;
}

._25rEj button {
    background: #2c333e;
    border: 0;
    outline: none;
    background-color: transparent;
    margin: auto;
}

._1-it4 ._Tf5yg {
    left: 0;
    position: absolute;
}

._1-it4 ._3-glk {
    position: absolute;
    right: 60px;
    width: 30px;
}
._1-it4 ._1ImUE {
    position: absolute;
    right: 90px;
    width: 30px;
}
._1-it4 ._hUQUb {
    position: absolute;
    right: 120px;
    width: 30px;
}

._1-it4 ._2sF6f {
    position: absolute;
    right: 30px;
    width: 30px;
}

._1-it4 ._96v4w {
    position: absolute;
    right: 0;
}

._1-it4 ._3y8ur {
    color: #fff;
    font-family: Arial, Helvetica, sans-serif;
    left: 45px;
    overflow: hidden;
    position: absolute;
    text-overflow: ellipsis;
    top: 7px;
    white-space: nowrap;
    width: calc(100% - 230px);
}

._1-it4 ._3y8ur ._2580h {
    color: #55b5e4;
    margin-right: 10px;
}

._1-it4 ._3y8ur ._13lOn {
    color: #fff;
}

._3sVga {
    height: 25px;
    overflow: hidden;
    position: relative;
    width: 100%;
}

._MuDap {
    border: 1px solid #787878;
    color: #000;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 12px;
    overflow: hidden;
    position: absolute;
    text-align: center;
    top: 0;
    white-space: nowrap;
    z-index: 3;
}

._BfY6X {
    z-index: 2000;
}

._3_QxJ {
    border-top: 1px solid #787878;
    height: 25px;
    overflow: hidden;
    position: relative;
    width: 100%;
}

._1dzE9 {
    border-width: 0;
    border-right: 1px solid #787878;
    display: none;
    position: absolute;
    top: 0;
}

._1Eie8 {
    color: #ddd;
    display: none;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 12px;
    padding-left: 5px;
    position: absolute;
    top: 12px;
}

._35Pdt {
    background: #4a4a4a;
    overflow-x: auto;
    overflow-y: hidden;
    width: 100%;
}
._1PPtY {
    background: #4a4a4a;
    overflow-x: auto;
    overflow-y: hidden;
    width: 100%;
    height: 10px;
}

/*.dragLine {
    height: 9px;
    position: fixed;
    width: 100%;
}

.dragLine p {
    border-bottom: 1px solid white;
    cursor: grab;
    display: block;
    margin: 0 auto;
    width: 30px;
}*/

._1uYMH {
    align-items: center;
    background: blue;
    border: 1px solid black;
    cursor: pointer;
    display: flex;
    font-size: 11px;
    height: 15px;
    justify-content: left;
    line-height: 11px;
    overflow: hidden;
    padding-left: 3px;
    position: absolute;
    text-transform: uppercase;
    white-space: nowrap;
    word-break: break-all;
}
._2Da1T {
    align-items: flex-end;
    display: inline-flex;
    flex-direction: column;
    height: 15px;
    width: 100%;
}
._aHcJI {
    display: block;
    pointer-events: none;
    position: fixed;
    z-index: 2000;
}
._18P3J {
    width: 100px;
}
._lFLN9 {
    height: 15px;
    position: relative;
    width: 100%;
}

._3XIUk {
    background: #88255f;
    height: 100%;
    left: 0;
    position: absolute;
    width: 2px;
    z-index: 1001;
}

._231K0 {
    background: #000;
    display: none;
    height: 100%;
    left: 0;
    position: absolute;
    width: 2px;
    z-index: 2;
}

._36txJ {
    background: #5cb85c88;
    height: 100%;
    left: 0;
    position: absolute;
    z-index: 1000;
}

._2R4Hi {
    background: #99cc00;
    height: 100%;
    left: 0;
    position: absolute;
    width: 3px;
    z-index: 1001;
}

._3o0Pk {
    background: rgb(255, 0, 127);
    height: 100%;
    left: 0;
    position: absolute;
    width: 3px;
    z-index: 1002;
}

._3Zk3c {
    cursor: ew-resize;
}

._1sgUD {
    margin-bottom: 5px;
    margin-top: 5px;
    min-height: 30px;
    position: relative;
}

._3-FG3 {
    position: absolute;
    z-index: 5000;
}

._32XFA {
    background: #2c333e;
    height: 30px;
    margin: 0;
    padding: 0;
    position: relative;
    width: 100%;
    border-top: 1px solid grey;
}
._3OEP_ {
    background: #2c333e;
    height: 40px;
    margin: 0;
    padding: 0;
    position: relative;
    width: 100%;
    border-top: 1px solid grey;
}

._1xECG {
    border: 1px solid grey;
    border-bottom-width: 0px;
    border-top-width: 0px;
    color: #fff;
    cursor: pointer;
    display: flex;
    height: 30px;
    transition: background-color 0.5s ease;
    width: 30px;
}

._Bio7K {
    border: 1px solid grey;
    border-bottom-width: 0px;
    border-top-width: 0px;
    color: #fff;
    cursor: pointer;
    display: flex;
    height: 30px;
    transition: background-color 0.5s ease;
    width: 40px;
}

._2GOd5 ._1xECG:hover {
    background: #2e5046;
}

._2GOd5 ._Bio7K:hover {
    background: #5cb85c88;
}

._1xECG svg {
    display: block;
    height: 20px;
    margin: auto;
    text-align: center;
}

._Bio7K svg {
    display: block;
    height: 20px;
    margin: auto;
    text-align: center;
    padding-left: 5px;
    padding-right: 5px;
    border-left-width: 2px;
    border-left-style: solid;
    border-right-width: 2px;
    border-right-style: solid;
}


._1thqk {
    display: flex;
    flex-direction: row;
    height: 10px;
    width: 100%;
}

._2GOd5 {
    display: flex;
    flex-direction: row;
    height: 30px;
    width: 100%;
}

._1thqk ._15hVr span {
    color: white;
    font-size: 10px;
    margin: auto;
    width: 94px;
}

._1thqk ._3-azQ span {
    color: white;
    font-size: 10px;
    margin: auto;
    width: 64px;
}

._1thqk ._15hVr {
    display: flex;
    flex-direction: row;
    height: 10px;
    margin: 0;
    width: 95px;
    text-align: center;
}

._1thqk ._3-azQ {
    display: flex;
    flex-direction: row;
    height: 10px;
    margin: 0 0 0 50px;
    width: 64px;
    text-align: center;
}

._2GOd5 ._15hVr {
    display: flex;
    flex-direction: row;
    height: 30px;
    margin: 0;
    width: 95px;
}

._2GOd5 ._3-azQ {
    display: flex;
    flex-direction: row;
    height: 30px;
    margin: 0 0 0 50px;
    width: 83px;
}

._2GOd5 ._lbGqs {
    border-radius: 3px;
    margin-right: 6px;
}

._2GOd5 ._1LdQr {
    border-bottom-left-radius: 3px;
    border-top-left-radius: 3px;
}

._2GOd5 ._2lKeM {
    border-bottom-right-radius: 3px;
    border-top-right-radius: 3px;
}

._2GOd5 ._1DYho {
    align-items: center;
    display: flex;
    justify-content: center;
    flex: 1 1;
    margin: 0 10px;
}

._2Xtb1 {
    cursor: pointer;
    height: 20px;
    position: relative;
    width: 210px;
}

._2Xtb1 ._uqJ9Q {
    background-image: linear-gradient(to bottom, #555e6d 0, #606a7a 100%);
    background-repeat: repeat-x;
    border-radius: 4px;
    box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);
    box-sizing: border-box;
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ff555e6d',endColorstr='#ff606a7a',GradientType=0);
    height: 12px;
    left: 5px;
    position: absolute;
    top: 4px;
    width: 200px;
    z-index: 1;

    -moz-box-sizing: border-box;
    -webkit-box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);
    -webkit-box-sizing: border-box;
}
._2Xtb1 ._ownBx {
    background-image: linear-gradient(to bottom, #555e6d 0, #606a7a 100%);
    background-repeat: repeat-x;
    border-radius: 4px;
    box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.15);
    box-sizing: border-box;
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ff555e6d',endColorstr='#ff606a7a',GradientType=0);
    height: 12px;
    left: 5px;
    position: absolute;
    top: 4px;
    width: 0;
    z-index: 2;

    -moz-box-sizing: border-box;
    -webkit-box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.15);
    -webkit-box-sizing: border-box;
}

._2Xtb1 ._pdstO {
    background-color: #337ab7;
    background-image: linear-gradient(to bottom, #337ab7 0, #2e6da4 100%);
    background-repeat: repeat-x;
    border: 0 solid transparent;
    border-radius: 50%;
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.2),
        0 1px 2px rgba(0, 0, 0, 0.05);
    cursor: col-resize;
    -webkit-filter: none;
            filter: none;
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ff337ab7',endColorstr='#ff2e6da4',GradientType=0);
    height: 20px;
    left: 0;
    margin-left: -10px;
    position: absolute;
    top: 0;
    width: 20px;
    z-index: 3;

    -webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.2),
        0 1px 2px rgba(0, 0, 0, 0.05);
}

._2GOd5 ._1mSaw {
    text-align: center;
    width: 120px;
}

._2GOd5 ._1mSaw ._1xECG {
    border-radius: 3px;
    color: #fff;
    margin-left: 10px;
    width: 100px;
}

/* add css module styles here (optional) */

._1zk04 {
    align-content: stretch;
    align-items: stretch;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    height: 100%;
    justify-content: flex-start;
    margin: 0;
    padding: 0;
    width: 100%;

    -webkit-touch-callout: none;
    -webkit-user-select: none;
    user-select: none;
}

._37NAy {
    align-content: stretch;
    align-items: stretch;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    height: 30px;
    justify-content: flex-start;
    margin: 0;
    padding: 0;
    width: 100%;

    -webkit-touch-callout: none;
    -webkit-user-select: none;
    user-select: none;
}

